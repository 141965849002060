import { KaImage } from '@components/primitive/Image';
import { Link } from '@components/primitive/Link';
import { IBlog } from '@interfaces/blog';
import { routes } from '@utils/routes';
import { join, map } from 'lodash';
import { FC } from 'react';
import blogCardStyles from '@scss/components/blog-card.scss';

interface IBlogCardProps {
  data: IBlog;
}

export const BlogCard: FC<IBlogCardProps> = ({ data }) => {
  const { name, thumbnail, tags, id, slug } = data;

  return (
    <>
      <style jsx>{blogCardStyles}</style>
      <div className="ks-blog-card">
        <div className="thumbnail">
          <Link
            className="tag"
            href={{
              pathname: routes.BLOG,
              query: {
                slug,
              },
            }}
            title={name}
          >
            <KaImage
              src={thumbnail}
              alt={name}
              objectFit="cover"
              className="image"
              ratio="square"
              useNextImage
              width={370}
              height={370}
            />
          </Link>
        </div>
        <div className="content">
          <div className="meta">
            {map(tags, (tag, idx) => (
              <span className="text" key={`blog-card-tags-${idx}`}>
                <Link
                  className="tag"
                  href={{
                    pathname: routes.BLOGS,
                    query: {
                      tag: id,
                    },
                  }}
                  title={name}
                  size="xs"
                  color="primary"
                >
                  {join(tag, ' & ')}
                </Link>
              </span>
            ))}
          </div>
          <Link
            className="name"
            href={{
              pathname: routes.BLOG,
              query: {
                slug,
              },
            }}
            size="lg"
            title=""
            color="secondary"
          >
            {name}
          </Link>
        </div>
        <div className="more">
          <Link
            href={{
              pathname: routes.BLOG,
              query: {
                slug,
              },
            }}
            className="link"
            size="sm"
            color="black"
            underline
            title={name}
            rightIcon={<i className="fa-regular fa-chevron-right icon" />}
          >
            Đọc tiếp
          </Link>
        </div>
      </div>
    </>
  );
};
