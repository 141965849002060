import { KaImage } from '@components/primitive/Image';
import { Link } from '@components/primitive/Link';
import classNames from 'classnames';
import { get, isEmpty, map, noop } from 'lodash';
import { useRouter } from 'next/router';
import { FC } from 'react';

export interface IBannerProps {
  data: any;
  action: string;
  size?: 'small' | 'medium';
  className?: string;
  width?: number;
  height?: number;
}

const BannerCard: FC<IBannerProps> = ({ data, action, size, className, width, height }) => {
  const router = useRouter();
  const { image, title, subTitle, color, buttons } = data;

  return (
    <div
      className={classNames('ks-home-banner', `-${color}`, `-${size}`, className)}
      style={{ color: color || 'unset' }}
      onClick={() => (!isEmpty(data?.link) ? router.push(data?.link) : noop())}
    >
      <div className="wrapper">
        <Link href="/" title="" className="link">
          <KaImage
            src={image || ''}
            alt={title}
            objectFit="cover"
            className="image"
            useNextImage
            width={760}
            height={250}
          />
        </Link>
        <div className="info">
          <div className="inner">
            <h2 className="title">
              <span
                className="link"
                style={{
                  ...(!isEmpty(get(data, 'titleColor')) && {
                    color: get(data, 'titleColor'),
                  }),
                }}
              >
                {title}
              </span>
            </h2>

            <p
              className="description"
              style={{
                ...(!isEmpty(get(data, 'subTitleColor')) && {
                  color: get(data, 'subTitleColor'),
                }),
              }}
            >
              {subTitle}
            </p>

            {map(buttons, (it, idx) => (
              <Link
                href={!isEmpty(it?.link) ? it?.link : '/'}
                title={title}
                size="sm"
                className="link"
                underline
                rightIcon={<i className="fa-regular fa-chevron-right icon" />}
                key={idx}
              >
                {it?.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerCard;
