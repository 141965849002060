import { useDevice } from '@hooks/useDevice';
import { filter, flatMapDepth, map, slice } from 'lodash';
import BannerCard from './BannerCard';

const Banners = ({ sliders }: any) => {
  const { isMobile } = useDevice();

  return (
    <section className="ks-home-banners">
      <div className="ks-container content">
        <div className="wrapper">
          {map(
            slice(
              flatMapDepth(map(filter(sliders?.items, { place: 2 }), ({ items }) => items)),
              0,
              2,
            ),
            (banner, idx) => (
              <BannerCard data={banner} action="Mua ngay" size="small" key={`home-banner-${idx}`} />
            ),
          )}
        </div>
      </div>
    </section>
  );
};

export default Banners;
