import { Rating } from '@components/compound/Rating';
import { KaImage } from '@components/primitive/Image';
import { map } from 'lodash';
import { useState } from 'react';
import { Navigation, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import TestimonialThumbnail from './TestimonialThumbnail';

const Testimonials = ({ quotes }: any) => {
  const [thumbs, setThumbs] = useState<any>(null);

  return (
    <section className="ks-home-testimonials">
      <div className="ks-container content">
        <h2 className="title">KHÁCH HÀNG CỦA CHÚNG TÔI NÓI GÌ!</h2>
        <div className="shape">
          <KaImage
            alt=""
            src="/images/h1_decor-3.png"
            objectFit="cover"
            useNextImage
            sizes="100%"
            priority
          />
        </div>
        <div className="slides">
          <button className="action -prev testimonial-action" title="prev slide">
            <i className="fa-regular fa-chevron-left" />
          </button>
          <div className="main">
            <Swiper
              modules={[Thumbs, Navigation]}
              thumbs={{ swiper: thumbs && !thumbs.destroyed ? thumbs : null }}
              className="ks-home-testimonial-slides"
              wrapperClass="wrapper"
              navigation={{
                nextEl: '.testimonial-action.-next',
                prevEl: '.testimonial-action.-prev',
              }}
            >
              {map(quotes?.items, (it, idx) => (
                <SwiperSlide key={idx}>
                  <div className="ks-home-testimonial-slide">
                    <div className="rating">
                      <Rating readOnly value={Number(it?.rating)} />
                    </div>
                    <p className="content">{it?.content}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <TestimonialThumbnail quotes={quotes} setThumbs={setThumbs} />
          </div>
          <button className="action -next testimonial-action" title="next slide">
            <i className="fa-regular fa-chevron-right" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
