import BlogSlide from '@components/compound/BlogSlide';
import { Link } from '@components/primitive/Link';
import { routes } from '@utils/routes';
import { size } from 'lodash';

const Blogs = ({ items }: any) => {
  if (!size(items)) return null;

  return (
    <section className="ks-home-blogs">
      <div className="ks-container content">
        <div className="header">
          <h2 className="title">TIN MỚI NHẤT</h2>
          <div className="viewmore -bottom">
            <Link
              href={routes.BLOGS}
              className="action"
              title="Blogs"
              underline
              color="primary"
              rightIcon={<i className="fa-regular fa-chevron-right icon" />}
            >
              Xem thêm
            </Link>
          </div>
        </div>

        <BlogSlide blogs={items} />
      </div>
    </section>
  );
};

export default Blogs;
