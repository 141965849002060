import { breakpoints } from '@utils/constants';
import { map } from 'lodash';
import { FC } from 'react';
import { Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

interface ITestimonialThumbnailProps {
  setThumbs: any;
  quotes: any;
}

const TestimonialThumbnail: FC<ITestimonialThumbnailProps> = ({ setThumbs, quotes }) => {
  return (
    <Swiper
      className="ks-home-testimonial-thumbnails"
      wrapperClass="wrapper"
      spaceBetween={20}
      watchSlidesProgress
      onSwiper={setThumbs}
      breakpoints={{
        [breakpoints.xs]: {
          slidesPerView: 3,
        },
        [breakpoints.md]: {
          slidesPerView: 4,
        },
      }}
      modules={[Thumbs]}
    >
      {map(quotes?.items, (it, idx) => (
        <SwiperSlide className="ks-home-testimonial-thumbnail" key={idx}>
          <p className="name">{it?.name}</p>
          {/* <p className="added">May 2023</p> */}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TestimonialThumbnail;
