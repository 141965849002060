import ProductSlides from '@components/compound/ProductSlides';
import { Link } from '@components/primitive/Link';
import { routes } from '@utils/routes';
import { get } from 'lodash';

const Products = ({ products }: any) => {
  return (
    <section className="ks-home-products">
      <div className="ks-container content">
        <div className="header">
          <h2 className="title">Sản Phẩm Bán Chạy</h2>
          <div className="readmore -bottom">
            <Link
              href={routes.PRODUCTS}
              className="action"
              title=""
              underline
              color="primary"
              rightIcon={<i className="fa-regular fa-chevron-right icon" />}
            >
              Xem thêm
            </Link>
          </div>
        </div>
        <ProductSlides products={get(products, 'items', [])} />
      </div>
    </section>
  );
};

export default Products;
