import { Link } from '@components/primitive/Link';
import FirstImage from '@svg/quantity/left-image.svg';
import SecondImage from '@svg/quantity/mid-image.svg';
import ThirdImage from '@svg/quantity/right-image.svg';
import { routes } from '@utils/routes';
import { get, map } from 'lodash';
import { useMemo } from 'react';

const Quantity = ({ blogs }: any) => {
  const QUANTITY = useMemo(
    () => [
      {
        image: <FirstImage />,
        classNameIcon: 'icon',
        title: 'THÀNH LẬP NĂM 1983',
        name: 'GIẢI PHÁP CHĂM SÓC SỨC KHỎE',
        description:
          'Đai đeo thảo dược cao cấp phytobelt giải pháp chăm sóc vòng 2 cho phụ nữ sau sinh',
        link: 'Câu chuyện của chúng tôi',
        route: {
          pathname: routes.BLOG,
          query: {
            slug: get(blogs?.items, '[1].slug', ''),
          },
        },
      },
      {
        image: <SecondImage />,
        classNameIcon: 'icon -right',
        title: 'CHẤT LƯỢNG',
        name: 'ĐIỀU TRỊ AN TOÀN',
        description: 'Thoát vị đĩa đệm cột sống cổ, các vấn đề cần biết',
        link: 'Y khoa thưởng thức',
        route: {
          pathname: routes.BLOG,
          query: {
            slug: get(blogs?.items, '[2].slug', ''),
          },
        },
      },
      {
        image: <ThirdImage />,
        classNameIcon: 'icon',
        title: 'TỰ NHIÊN & TINH KHIẾT',
        name: 'NGUY CƠ KHÁNG THUỐC',
        description:
          'Giải pháp phòng bệnh và khắc phục tình trạng kháng thuốc bằng thảo dược thiên nhiên an toàn ',
        link: 'Giải pháp của chúng tôi',
        route: {
          pathname: routes.BLOG,
          query: {
            slug: get(blogs?.items, '[3].slug', ''),
          },
        },
      },
    ],
    [blogs?.items],
  );
  return (
    <section className="ks-home-quantity">
      <div className="ks-container content">
        {map(QUANTITY, (item, idx) => (
          <div key={`quantity-${idx}`} className="quantity">
            <div className="information">
              <div className="image">
                <div className={item.classNameIcon}>{item.image}</div>
              </div>
              <div className="title">{item.title}</div>
              <div className="name">{item.name}</div>
              <div className="description">{item.description}</div>
            </div>

            <Link
              className="link"
              href={item?.route}
              title="blogs"
              rightIcon={<i className="fa-solid fa-chevron-right fa-xs icon" />}
              underline
              color="black"
              size="sm"
            >
              {item.link}
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Quantity;
