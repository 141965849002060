import { useBlogsQuery } from '@/kytesoft-client/features/blogs';
import { useProductCategoriesQuery } from '@/kytesoft-client/features/productCategories';
import { useProductsQuery } from '@/kytesoft-client/features/products';
import { useQuotesQuery } from '@/kytesoft-client/features/quotes';
import { useSlidersQuery } from '@/kytesoft-client/features/sliders';
import KsLayout from '@/layout';
import styles from '@scss/pages/home.scss';
import { LIMIT } from '@utils/limit';
import { isEmpty } from 'lodash';
import dynamic from 'next/dynamic';
import Banners from './Banners';
import Blogs from './Blogs';
import Collections from './Collections';
import Products from './Products';
import Quantity from './Quantity';
import Testimonials from './Testimonials';

const Sliders = dynamic(() => import('./Slider'), { ssr: false });

const Trailer = dynamic(() => import('./Trailer'), { ssr: false });

export default function Home() {
  const { data: sliders }: any = useSlidersQuery({
    site: 1,
    options: {},
  });
  const { data: products }: any = useProductsQuery(
    // @ts-ignore
    {
      page: 1,
      limit: LIMIT.HOME_PRODUCTS,
    },
  );
  const { data: categories }: any = useProductCategoriesQuery({
    options: {},
  });
  // @ts-ignore
  const { data: quotes }: any = useQuotesQuery({ page: 1, limit: 10, site: 1 });
  // @ts-ignore
  const { data: blogs }: any = useBlogsQuery({ page: 1, limit: LIMIT.HOME_BLOGS });

  return (
    <>
      <style jsx>{styles}</style>
      <KsLayout
        og={{
          title: 'Thaoduocanhduong - Mua hàng online giá tốt, hàng chuẩn, ship nhanh',
          description:
            'Thỏa sức mua sắm qua mạng hàng ngàn mặt hàng sách, điện tử, đồ gia dụng, quà tặng, thời trang, làm đẹp & sức khỏe tại Tiki với giá rẻ hơn và khuyến mãi hấp dẫn',
          url: 'https://Thaoduocanhduong.kytesoft.com',
          type: 'website',
          siteName: 'Thaoduocanhduong.Kytesoft.com',
          images: [
            {
              url: 'https://ik.imagekit.io/hainv198/kytesoft/651a4a22ee18379953d92a3e/seo/logo.png',
              width: 169,
              height: 158,
              type: 'image/png',
            },
          ],
          locale: 'vi_VN',
        }}
        description="description"
        title="Home"
      >
        <div className="ks-home">
          {!isEmpty(sliders?.items) && <Sliders sliders={sliders} />}

          <Banners sliders={sliders} />

          <Products products={products} />

          <Collections categories={categories} />

          <Quantity blogs={blogs} />

          <Testimonials quotes={quotes} />

          <Blogs items={blogs?.items || []} />

          <Trailer />
        </div>
      </KsLayout>
    </>
  );
}
