import { KaImage } from '@components/primitive/Image';
import { Link } from '@components/primitive/Link';
import { LIMIT } from '@utils/limit';
import { routes } from '@utils/routes';
import { map, slice } from 'lodash';

const Collections = ({ categories }: any) => {
  return (
    <section className="ks-home-collections">
      <div className="ks-container content">
        <h2 className="title">Bộ sưu tập nổi bật</h2>

        <div className="grid">
          {map(slice(categories, 0, LIMIT.HOME_COLLECTIONS), ({ id, image, name, slug }: any) => (
            <div className="card" key={`collection-${id}`}>
              <div className="ks-home-collection">
                <Link
                  className="link"
                  href={{
                    pathname: routes.PRODUCTS,
                    query: {
                      category: slug,
                    },
                  }}
                  title={name}
                >
                  <KaImage
                    src={image || ''}
                    alt={image || ''}
                    objectFit="cover"
                    className="image"
                    ratio="square"
                    useNextImage
                    sizes="100%"
                    width={480}
                    height={480}
                  />
                </Link>

                <div className="container">
                  <Link
                    href={{
                      pathname: routes.PRODUCTS,
                      query: {
                        category: slug,
                      },
                    }}
                    className="title"
                    color="white"
                    size="lg"
                    title={name}
                  >
                    {name}
                  </Link>

                  <Link
                    href={{
                      pathname: routes.PRODUCTS,
                      query: {
                        category: slug,
                      },
                    }}
                    title={name}
                    className="link"
                    size="sm"
                    color="white"
                    rightIcon={<i className="fa-regular fa-chevron-right icon" />}
                  >
                    Mua ngay
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Collections;
